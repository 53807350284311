import Alpine from 'alpinejs'

import collapse from '@alpinejs/collapse'

// Alpine.plugin(collapse)

window.quotationForm = function (makeOffer) {
	console.log(makeOffer)
	return {
		accepted: '',
		maintenance: '',
		makeOffer: makeOffer == 1,
		finished() {
			return (
				this.accepted === '0' ||
				(this.accepted === '1' && this.makeOffer == '1' && this.maintenance !== '') ||
				(this.accepted === '1' && this.makeOffer == '0' && this.maintenance === '')
			)
		},
	}
}

window.Alpine = Alpine

Alpine.start()
